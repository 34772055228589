import React from "react";

import "./InfiniteCarousel.css";

const InfiniteCarousel = () => {
  return (
    /*  <div className="codeutsava__sponsers-carousel-container">
             <div className="codeutsava__sponsers-carousel">
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser1} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <h1>HELLO</h1>
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser2} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser3} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser4} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser5} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser6} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser7} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser8} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser9} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser10} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser11} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item-block">
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser1} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser2} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser3} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser4} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser5} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser6} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser7} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser8} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser9} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser10} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser11} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item-block">
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser1} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser2} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser3} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser4} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser5} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser6} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser7} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser8} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser9} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser10} />
                 </div>
                 <div className="codeutsava__sponsers-carousel-item">
                     <img src={sponser11} />
                 </div>
             </div>
         </div> */

    <div className="h4b_reg_text_wrapper">
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
      <p>Registration starting soon</p>
      <p>Follow our socials !</p>
    </div>
  );
};

export default InfiniteCarousel;
